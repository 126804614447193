/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentRef, FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import { getContainingRoles, getHighestRole, Roles } from '../../models/Role';
import User from '../../models/User';
import ClientService from '../../services/ClientService';
import { Option } from '../Option';
import Button, { iconButtonClasses, iconButtonContainerClasses, ButtonSize, ButtonType } from '../shared/form-control/Button';
import { Heading, HeadingSize } from '../shared/text/Heading';
import UserInvitation from '../../models/UserInvitation';
import InviteOrSearchModal from './InviteOrSearchModal';
import InfoIcon from '../../components/shared/icon/InfoIcon';
import usePermissions from '../../hooks/permissions/usePermissions';
import { useCurrentClient } from '../../global-state/Clients';
import OrgMemberFieldsModal from '../my-org/OrgMemberFieldsModal';
import CogIcon from '../shared/icon/CogIcon';
import { DefaultMemberFieldKeys, defaultMemberFields, MemberField, MemberFieldKey } from '../../models/ClientMemberFields';
import useFetchClientUsers from '../../hooks/useFetchClientUsers';
import UsersGroupSolidIcon from '../shared/icon/UsersGroupSolidIcon';
import Popover from '../shared/popover/Popover';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import Tooltip from '../shared/Tooltip';
import SearchIcon from '../shared/icon/SearchIcon';
import { Input, InputStyle } from '../shared/form-control/Input';
import FunnelIcon from '../shared/icon/FunnelIcon';
import SortIcon from '../shared/icon/SortIcon';
import {
  Cell,
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  Header,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import SorterIcon from '../shared/icon/SorterIcon';
import Checkbox from '../shared/form-control/Checkbox';
import UserPermissionsTableFilterContent from './UserPermissionsTableFilterContent';
import Badge from '../shared/badge/Badge';
import ProfileAvatar, { ImageSize } from '../shared/profile-image/ProfileAvatar';
import UserPermissionsFilterOverview from './UserPermissionsFilterOverview';
import UserPermissionsSortingOverview from './UserPermissionsSortingOverview';
import { useShallow } from 'zustand/react/shallow';
import ClickEditCell from './UserPermissionsCells/ClickEditCell';
import ContextMenu, { ContextMenuItem } from '../shared/ContextMenu';
import UserService from '../../services/UserService';
import AuthService from '../../services/AuthService';
import DefaultCellRenderer from './UserPermissionsCells/DefaultCellRenderer';
import { DatePicker, DatePickerType } from '../shared/form-control/DatePicker';
import DateUtils from '../../utils/DateUtils';
import TagDropdownCell from './UserPermissionsCells/TagDropdownCell';
import RoleCell from './UserPermissionsCells/RoleCell';
import { ChevronIcon, ChevronType } from '../shared/icon/ChevronIcon';
import { UserProfile } from '../../models/AuthModels';
import useFetchClientTags from '../../hooks/useFetchClientTags';
import { ClientTagType } from '../../models/ClientTag';
import LanguageUtils from '../../utils/LanguageUtils';
import Loader from '../shared/Loader';
import { useTableViewStyles } from '../../hooks/useTableViewStyles';
import { useCurrentUser } from '../../global-state/Auth';
import { useQueryClient } from '@tanstack/react-query';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../shared/modal/variants/StandardModal';

const assignableRoles = Object.values(Roles).filter((x) => x !== Roles.SuperAdmin);
const EMPTY_ARRAY: never[] = [];
const DEFAULT_COLUMN_SIZE = 250;
const PROFILE_IMAGE_WIDTH = 65;

const CLICK_EDIT_CELLS: DefaultMemberFieldKeys[] = [
  MemberFieldKey.Firstname,
  MemberFieldKey.LastName,
  MemberFieldKey.Address,
  MemberFieldKey.EmployeeId,
  MemberFieldKey.OfficeLocation,
  MemberFieldKey.PhoneNumber,
] as const;
const CHECK_EDIT_FIELDS: DefaultMemberFieldKeys[] = [MemberFieldKey.BuilderAccess] as const;
const DATE_EDIT_CELLS: DefaultMemberFieldKeys[] = [MemberFieldKey.Birthday, MemberFieldKey.StartDate] as const;
const TAG_EDIT_CELLS: DefaultMemberFieldKeys[] = [MemberFieldKey.Department, MemberFieldKey.Group, MemberFieldKey.Position] as const;

const UserPermissions: FC = () => {
  const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false);
  const [memberFieldsModalOpen, setMemberFieldsModalOpen] = useState(false);
  const hasPermission = usePermissions();
  const [currentClient, setClient] = useCurrentClient(useShallow((x) => [x.value, x.setValue]));

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(true);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const { data: usersData = EMPTY_ARRAY, isLoading, refetch: refetchUsers } = useFetchClientUsers(true);
  const query = useQueryClient();

  useEffect(() => {
    refetchUsers();

    return () => {
      query.invalidateQueries({
        queryKey: ['clientUsers', currentClient?.id, true],
      });
    };
  }, [currentClient?.id, query, refetchUsers]);

  const [users, setUsers] = useState(usersData);
  useEffect(() => {
    setUsers(usersData);
  }, [usersData]);

  const { data: userTags } = useFetchClientTags([ClientTagType.UserDepartment, ClientTagType.UserGroup, ClientTagType.UserPosition]);

  const { t, i18n } = useTranslation(['organisation', 'common', 'table-view']);
  const toasts = useToasts();
  const [currentUser, setCurrentUser] = useCurrentUser(useShallow((x) => [x.value, x.setValue]));
  const [toRemoveUser, setToRemoveUser] = useState<User | null>(null);

  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const totalFilters = useMemo(() => columnFilters.length, [columnFilters]);
  const totalSorts = useMemo(() => sorting.length, [sorting]);

  const filterColumnPopoverRef = useRef<Record<string, ComponentRef<typeof Popover>>>({});

  const inviteUser = (userToInvite: UserInvitation) => {
    if (!currentClient?.id) {
      toasts.addToast({
        title: t('organisation:permissions.toasts.invite-failed'),
        type: ToastType.ERROR,
      });
    } else {
      ClientService.inviteUser(currentClient?.id, userToInvite)
        .then((res) => {
          if (res.data.isNewUser) {
            toasts.addToast({
              title: t('organisation:permissions.toasts.invited'),
              description: t('organisation:permissions.toasts.invited-desc', {
                email: userToInvite?.email,
                client: currentClient?.name,
              }),
              type: ToastType.SUCCESS,
              expiresInMs: 5000,
            });
          } else {
            toasts.addToast({
              title: t('organisation:permissions.toasts.invite-failed'),
              description: t('organisation:permissions.toasts.invite-failed-desc'),
              type: ToastType.INFO,
              expiresInMs: 10000,
            });
          }
          refetchUsers();
          setInviteUserModalOpen(false);
        })
        .catch((err) => {
          toasts.addToast({
            title: t('organisation:permissions.toasts.invite-failed'),
            description: err?.data?.meta?.message,
            type: ToastType.ERROR,
          });
        });
    }
  };

  const removeUser = useCallback(
    (user: User) => {
      if (currentClient?.id && user.id) {
        ClientService.removeUser(currentClient?.id, user.id).then((res) => {
          if (res.data) {
            setToRemoveUser(null);
            setUsers((prev) => prev.filter((u) => u.id !== user.id));
            toasts.addToast({
              title: t('organisation:permissions.toasts.removed'),
              description: t('organisation:permissions.toasts.removed-desc', {
                email: user.email,
                client: currentClient?.name,
              }),
              type: ToastType.SUCCESS,
              expiresInMs: 5000,
            });
          }
        });
      }
    },
    [currentClient?.id, currentClient?.name, t, toasts],
  );

  const disableUser = useCallback(
    (user: User) => {
      UserService.disableUser(user.id).then(() => {
        toasts.addToast({ title: t('organisation:permissions.toasts.disabled'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        setUsers((prev) => prev.map((u) => (u.id === user.id ? { ...u, active: false } : u)));
      });
    },
    [t, toasts],
  );

  const activateUser = useCallback(
    (user: User) => {
      UserService.enableUser(user.id).then(() => {
        toasts.addToast({ title: t('organisation:permissions.toasts.activated'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        setUsers((prev) => prev.map((u) => (u.id === user.id ? { ...u, active: true } : u)));
      });
    },
    [t, toasts],
  );

  const resetUserPassword = useCallback(
    (user: User) => {
      if (user?.email) {
        AuthService.forgotPassword(user.email as string).then(() => {
          toasts.addToast({ title: t('common:permissions.toasts.reset-password'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        });
      }
    },
    [t, toasts],
  );

  const resetUser2Fa = useCallback(
    (user: User) => {
      if (user?.id) {
        UserService.reset2Fa(user.id as string).then(() => {
          toasts.addToast({ title: t('common:permissions.toasts.reset-2fa'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        });
      }
    },
    [t, toasts],
  );

  const onInviteClose = useCallback(() => {
    setInviteUserModalOpen(false);
  }, []);

  const inviteRoleFilter = useCallback((role: Option<string, string>) => {
    return assignableRoles.indexOf(role.value as (typeof assignableRoles)[number]) > -1;
  }, []);

  const onCellChanged = useCallback(
    <TField extends keyof User>(user: User, field: TField, value: User[TField], requestExtras?: Partial<UserProfile>) => {
      let updateUserPromise;
      if (field === 'roles') {
        updateUserPromise = UserService.updateRole(user.id, currentClient!.id, value as any).then(() => {
          setUsers((prev) =>
            prev.map((u) => (u.id === user.id ? { ...u, roles: { ...u.roles, [currentClient!.id]: getContainingRoles(value as any) } } : u)),
          );

          return { data: { ...user, roles: { ...user.roles, [currentClient!.id]: getContainingRoles(value as any) } } };
        });
      } else if (field === 'isArchitect') {
        updateUserPromise = UserService.updateArchitectPermission(user.id, !!value).then(() => {
          return { data: { ...user, isArchitect: !!value } };
        });
      } else {
        const isUserField = defaultMemberFields.find((x) => x.key === field)?.isUserField;
        const request: UserProfile = { ...user, languageCode: user.language, ...requestExtras };
        if (isUserField) {
          (request as any)[field] = value;
        } else {
          request.clientCustomData ??= {};
          (request.clientCustomData as any)[currentClient!.id] ??= {};
          (request.clientCustomData as any)[currentClient!.id][field] = value;
        }
        updateUserPromise = UserService.updateUserProfile(user.id, request).then((res) => {
          return res;
        });
      }

      updateUserPromise?.then((res) => {
        toasts.addToast({ title: t('permissions.toasts.user-details-saved'), type: ToastType.SUCCESS, expiresInMs: 5000 });

        setUsers((prev) =>
          prev.map((u) =>
            u.id === user.id
              ? {
                  ...u,
                  ...res.data,
                  // Roles aren't returned from BE for this EP
                  roles: u.roles,
                }
              : u,
          ),
        );

        // If the updated user is the currentUser, update only the matching fields
        if (user.id === currentUser?.id) {
          setCurrentUser((prev) => {
            if (!prev) return prev;

            const updatedUser = { ...prev, clientCustomData: { ...(res.data.clientCustomData ?? {}) } };

            // Only update fields that exist in both `currentUser` and `res`
            (Object.keys(res.data) as (keyof UserProfile)[]).forEach((key) => {
              if (key in prev) {
                updatedUser[key as keyof User] = (res.data as any)[key] as never;
              }
            });

            if (Object.keys(updatedUser.roles).length === 0) {
              updatedUser.roles = prev.roles;
            }

            return updatedUser;
          });
        }
      });
    },
    [currentClient, currentUser?.id, setCurrentUser, t, toasts],
  );

  const displayedCells = useMemo(() => {
    return ['profileImage'].concat(
      (currentClient?.memberFields?.length ? currentClient.memberFields : defaultMemberFields.filter((x) => x.isRequired)).map((x) => x.key),
    );
  }, [currentClient?.memberFields]);

  const columnFilterFn = useCallback((row: Row<User>, columnId: string, filterValue: string | number | (string | number)[]) => {
    const value = row.getValue<string | string[]>(columnId)?.toString() || '';

    if (Array.isArray(filterValue)) {
      return filterValue.length === 0 || filterValue.some((x) => value === x.toString());
    }

    return value === filterValue.toString();
  }, []);

  const columnDefinitions = useMemo(() => {
    const result: (ColumnDef<User> & { id: DefaultMemberFieldKeys })[] = [
      {
        id: 'profileImage',
        accessorFn: (row) => row,
        size: PROFILE_IMAGE_WIDTH,
        minSize: PROFILE_IMAGE_WIDTH,
        enableSorting: false,
        enableGlobalFilter: false,
        enableColumnFilter: false,
        enableResizing: false,
        meta: {
          field: 'userImageId',
        },
      },
      {
        id: MemberFieldKey.Firstname,
        accessorKey: 'firstName',
        sortingFn: 'text',
        meta: {
          field: 'firstName',
        },
      },
      {
        id: MemberFieldKey.LastName,
        accessorKey: 'lastName',
        sortingFn: 'text',
        meta: {
          field: 'lastName',
        },
      },
      {
        id: MemberFieldKey.Email,
        accessorKey: 'email',
        sortingFn: 'alphanumeric',
        meta: {
          field: 'email',
        },
      },
      {
        id: MemberFieldKey.BuilderAccess,
        accessorFn: (row) => !!row?.isArchitect,
        sortingFn: 'basic',
        enableGlobalFilter: false,
        meta: {
          field: 'isArchitect',
        },
      },
      {
        id: MemberFieldKey.Role,
        accessorFn: (row) => {
          if (!row.roles || !currentClient?.id) return null;

          const role = getHighestRole(row.roles[currentClient.id] ?? []);
          return t(`common:roles.${role}`).toString();
        },
        sortingFn: 'text',
        enableGlobalFilter: false,
        meta: {
          field: 'roles',
        },
      },
      {
        id: MemberFieldKey.Status,
        accessorKey: 'active',
        sortingFn: 'basic',
        enableGlobalFilter: false,

        meta: {
          field: 'active',
        },
      },
      {
        id: MemberFieldKey.Address,
        accessorFn: (row) => row.address?.address,
        sortingFn: 'alphanumeric',
        meta: {
          field: 'address',
        },
      },
      {
        id: MemberFieldKey.Birthday,
        accessorFn: (row) => (row.birthday ? DateUtils.formatDate(new Date(row.birthday)) : null),
        sortingFn: 'alphanumeric',
        meta: {
          field: 'birthday',
        },
      },
      {
        id: MemberFieldKey.Department,
        accessorFn: (row) =>
          LanguageUtils.getTranslation(
            'name',
            userTags?.find((x) => x.id === row.clientCustomData?.[currentClient!.id]?.departmentId)?.translations ?? {},
            i18n.language,
          ),
        sortingFn: 'text',
        meta: {
          field: 'departmentId',
        },
      },
      {
        id: MemberFieldKey.EmployeeId,
        accessorFn: (row) => row.clientCustomData?.[currentClient!.id]?.employeeId,
        sortingFn: 'alphanumeric',

        meta: {
          field: 'employeeId',
        },
      },
      {
        id: MemberFieldKey.Group,
        accessorFn: (row) =>
          LanguageUtils.getTranslation(
            'name',
            userTags?.find((x) => x.id === row.clientCustomData?.[currentClient!.id]?.groupId)?.translations ?? {},
            i18n.language,
          ),
        sortingFn: 'text',
        meta: {
          field: 'groupId',
        },
      },
      {
        id: MemberFieldKey.OfficeLocation,
        accessorFn: (row) => row.clientCustomData?.[currentClient!.id]?.officeLocation?.address,
        sortingFn: 'alphanumeric',
        meta: {
          field: 'officeLocation',
        },
      },
      {
        id: MemberFieldKey.Position,
        accessorFn: (row) =>
          LanguageUtils.getTranslation(
            'name',
            userTags?.find((x) => x.id === row.clientCustomData?.[currentClient!.id]?.positionId)?.translations ?? {},
            i18n.language,
          ),
        sortingFn: 'text',
        meta: {
          field: 'positionId',
        },
      },
      {
        id: MemberFieldKey.PhoneNumber,
        accessorKey: 'phoneNumber',
        sortingFn: 'alphanumeric',
        meta: {
          field: 'phoneNumber',
        },
      },
      {
        id: MemberFieldKey.StartDate,
        accessorFn: (row) =>
          row.clientCustomData?.[currentClient!.id]?.startDate
            ? DateUtils.formatDate(new Date(row.clientCustomData[currentClient!.id].startDate as string))
            : null,
        sortingFn: 'datetime',
        meta: {
          field: 'startDate',
        },
      },
    ];

    for (const col of result) {
      col.filterFn = 'pbkFilter' as any;
    }

    return result;
  }, [currentClient, i18n.language, t, userTags]);

  const { getCommonPinningStyles } = useTableViewStyles();

  const tableColumns = useMemo<ColumnDef<User>[]>(() => {
    return displayedCells.map((cellId) => columnDefinitions.find((x) => x.id === cellId)!).filter(Boolean);
  }, [columnDefinitions, displayedCells]);

  const table = useReactTable({
    data: users,
    columns: tableColumns,
    columnResizeMode: 'onChange',
    defaultColumn: {
      size: DEFAULT_COLUMN_SIZE,
      minSize: 150,
    },
    enableColumnResizing: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    enableSortingRemoval: true,
    enableSorting: true,
    enableMultiSort: true,
    enableFilters: true,
    state: {
      sorting,
      globalFilter: searchTerm,
      columnFilters,
      columnPinning: { left: ['profileImage'] },
    },
    onSortingChange: setSorting,
    isMultiSortEvent: () => true,
    enableGlobalFilter: true,
    globalFilterFn: 'auto',
    onGlobalFilterChange: setSearchTerm,
    onColumnFiltersChange: setColumnFilters,
    filterFns: {
      pbkFilter: columnFilterFn,
    },
  });

  useLayoutEffect(() => {
    const ref = scrollContainerRef.current;
    let colSize = DEFAULT_COLUMN_SIZE;
    if (ref) {
      const elWidth = ref.clientWidth ?? 0;
      const scrollWidth = ref.scrollWidth ?? 0;

      // If we cannot scroll, set the column sizes to fit the container (aka width / num of columns)
      if (elWidth >= scrollWidth) {
        colSize = (elWidth - PROFILE_IMAGE_WIDTH) / (displayedCells.length - 1);
      }
    }

    const columns = tableColumns
      .filter((x) => x.id !== 'profileImage')
      .reduce(
        (acc, col) => {
          acc[col.id!] = colSize;
          return acc;
        },
        {} as Record<string, number>,
      );

    table.setColumnSizing(() => columns);
  }, [displayedCells.length, users.length, table, tableColumns]);

  const headerCellContent = useCallback(
    (header: Header<User, unknown>) => {
      if (header.id === 'profileImage') return <div>&nbsp;</div>;

      const content = t(`organisation:permissions.member-fields-config.fields.${header.id}` as any);

      return (
        <Tooltip text={content} truncatedTextMode>
          {(tooltip) => (
            <div className="select-none truncate" {...tooltip}>
              {content}
            </div>
          )}
        </Tooltip>
      );
    },
    [t],
  );

  const dataCellContent = useCallback(
    (cell: Cell<User, unknown>) => {
      if (cell.column.id === MemberFieldKey.Status) {
        const active = cell.getValue() as boolean;
        return (
          <Badge
            backgroundClass={active ? 'bg-semantic-1 bg-opacity-15' : 'bg-gray-5 bg-opacity-75'}
            textClass={active ? 'text-semantic-1' : 'text-gray-2'}
            text={t(`organisation:permissions.user-status.${active ? 'active' : 'inactive'}`)}
          />
        );
      } else if (cell.column.id === 'profileImage') {
        return (
          <div className="flex items-center justify-center">
            <ProfileAvatar user={cell.getValue() as User} size={ImageSize.S} />
          </div>
        );
      } else if (cell.column.id === MemberFieldKey.Role) {
        return <RoleCell cell={cell} onChange={(newRole) => onCellChanged(cell.row.original, 'roles', newRole as any)} />;
      }

      if (CLICK_EDIT_CELLS.includes(cell.column.id as DefaultMemberFieldKeys)) {
        return (
          <ClickEditCell
            cell={cell}
            searchTerm={searchTerm}
            onChange={(value) => onCellChanged(cell.row.original, (cell.column.columnDef.meta as any)?.field as keyof User, value)}
          />
        );
      }

      if (CHECK_EDIT_FIELDS.includes(cell.column.id as DefaultMemberFieldKeys)) {
        return (
          <Checkbox
            value={cell.getValue() as boolean}
            onChange={(value) => onCellChanged(cell.row.original, (cell.column.columnDef.meta as any)?.field as keyof User, value)}
          />
        );
      }

      if (DATE_EDIT_CELLS.includes(cell.column.id as DefaultMemberFieldKeys)) {
        const value = cell.getValue() as string | null;
        const originalValue = (cell.row.original[(cell.column.columnDef.meta as any)?.field as keyof User] ??
          (cell.row.original.clientCustomData?.[currentClient!.id] as any)?.[(cell.column.columnDef.meta as any)?.field]) as string;

        return (
          <DatePicker
            date={originalValue ? new Date(originalValue) : new Date()}
            onChange={(value) =>
              onCellChanged(
                cell.row.original,
                (cell.column.columnDef.meta as any)?.field as keyof User,
                value ? DateUtils.formatDateForStorage(value) : null,
              )
            }
            type={DatePickerType.BUTTON}
            buttonClassName="min-w-full !bg-transparent !text-gray-500 !font-normal !shadow-none hover:!scale-100 focus:!scale-100 [&_*]:justify-start hover:!bg-gray-5 focus:!bg-gray-5"
            buttonContent={value || t('permissions.date-picker.no-date')}
            notBefore={null}
            buttonIgnoreMinWidth
          />
        );
      }

      if (TAG_EDIT_CELLS.includes(cell.column.id as DefaultMemberFieldKeys)) {
        return (
          <TagDropdownCell
            cell={cell}
            onChange={(value, keepGroupAssignments) =>
              onCellChanged(cell.row.original, (cell.column.columnDef.meta as any)?.field as keyof User, value, { keepGroupAssignments })
            }
          />
        );
      }

      return <DefaultCellRenderer cell={cell} searchTerm={searchTerm} />;
    },
    [currentClient, onCellChanged, searchTerm, t],
  );

  const boolFilterText = useMemo(() => {
    return {
      status: {
        true: t('organisation:permissions.user-status.active'),
        false: t('organisation:permissions.user-status.inactive'),
      },
      builderAccess: {
        true: t('common:on'),
        false: t('common:off'),
      },
    } as Record<DefaultMemberFieldKeys, Record<'true' | 'false', string>>;
  }, [t]);

  const contextItems = useCallback(
    (user: User) => {
      return [
        {
          title: t('organisation:permissions.context-menu.reset-password'),
          onClick: () => {
            resetUserPassword(user);
          },
          hide: !user.active || import.meta.env.VITE_SSO_ENABLED === 'true',
        },
        {
          title: t('organisation:permissions.context-menu.reset-2fa'),
          onClick: () => {
            resetUser2Fa(user);
          },
          hide: !user.active || import.meta.env.VITE_SSO_ENABLED === 'true',
        },
        {
          hasDivider: true,
          disabled: true,
          hide: !user.active || import.meta.env.VITE_SSO_ENABLED === 'true',
        },
        {
          customRenderer: () => {
            return <span className="text-semantic-2">{t('organisation:permissions.context-menu.remove')}</span>;
          },
          onClick: () => {
            setToRemoveUser(user);
          },
        },
        {
          customRenderer: () => {
            return <span className="text-semantic-2">{t('organisation:permissions.context-menu.deactivate')}</span>;
          },
          hide: !hasPermission(Roles.SuperAdmin) || !user.active,
          onClick: () => {
            disableUser(user);
          },
        },
        {
          title: t('organisation:permissions.context-menu.activate'),
          onClick: () => {
            activateUser(user);
          },
          hide: !hasPermission(Roles.SuperAdmin) || user.active,
        },
      ] as ContextMenuItem[];
    },
    [activateUser, disableUser, hasPermission, resetUser2Fa, resetUserPassword, t],
  );

  useLayoutEffect(() => {
    const ref = scrollContainerRef.current;
    if (!ref) return;

    const handler = () => {
      const elWidth = ref.clientWidth ?? 0;
      const scrollWidth = ref.scrollWidth ?? 0;
      const scrollLeft = ref.scrollLeft;

      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(elWidth + scrollLeft < scrollWidth);
    };
    handler();

    const mouseScrollHandler = (e: WheelEvent) => {
      if (!e.shiftKey) return;

      e.preventDefault();
      if (e.deltaY !== 0) {
        ref.scrollLeft += e.deltaY;
        handler();
      }
    };

    ref.addEventListener('resize', handler);
    ref.addEventListener('scroll', handler);
    ref.addEventListener('wheel', mouseScrollHandler);

    return () => {
      ref.removeEventListener('resize', handler);
      ref.removeEventListener('scroll', handler);
      ref.removeEventListener('wheel', mouseScrollHandler);
    };
  }, [displayedCells.length, users.length]);

  const onScroll = useCallback((delta: number) => {
    const ref = scrollContainerRef.current;
    if (!ref) return;

    // smooth scroll
    ref.scrollTo({
      left: ref.scrollLeft + delta,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="flex h-full flex-col pt-6">
      <div className="flex items-center justify-between pb-3">
        <Heading size={HeadingSize.H4}>{t('organisation:permissions.heading')}</Heading>
        <div className="flex gap-4">
          <Button
            data-cy="show-invite-modal"
            disabled={!hasPermission(Roles.TeamLead)}
            type={ButtonType.PRIMARY}
            size={ButtonSize.S}
            onClick={() => setInviteUserModalOpen(true)}
          >
            <Button.Slot name="Icon">
              <UsersGroupSolidIcon className="h-4 w-4 text-white" />
            </Button.Slot>
            {t('common:permissions.buttons.invite')}
          </Button>
        </div>
      </div>

      <div className="mt-4 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Heading size={HeadingSize.H6} className="font-medium">
            {t('organisation:permissions.subHeading')}
          </Heading>
          <span className="text-gray-4 mt-[2px] font-bold"> • </span>
          <span className="text-dpm-14 mt-[4px]">
            {t('organisation:permissions.result-count', { count: table.getRowCount(), total: users?.length ?? 0 })}
          </span>
        </div>

        <div className={`flex flex-shrink-0 items-center gap-2 pr-1`}>
          <div className="flex items-center">
            <div
              className={`overflow-hidden transition-all duration-300 ease-in-out ${isSearchExpanded ? 'mr-2 w-64 px-1 opacity-100' : 'mr-0 w-0 px-0 opacity-0'} `}
            >
              <Input
                style={InputStyle.MINIMAL}
                placeholder={t('organisation:permissions.search.placeholder')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onClear={() => setSearchTerm('')}
                autoFocus={isSearchExpanded}
              />
            </div>
            <Tooltip text={t('organisation:permissions.tooltips.search')}>
              {(tooltip) => (
                <div {...tooltip}>
                  {
                    <SearchIcon
                      className={`${iconButtonClasses} ${searchTerm.length > 0 ? '!bg-primary-2 bg-opacity-25' : ''}`}
                      onClick={() => setIsSearchExpanded((prev) => !prev)}
                    />
                  }
                </div>
              )}
            </Tooltip>
          </div>

          <div className={`flex flex-shrink-0 items-center gap-2 !pr-1`}>
            <Popover
              ref={(ref) => (filterColumnPopoverRef.current['filtering'] = ref!)}
              content={<UserPermissionsFilterOverview table={table} filters={columnFilters} boolValues={boolFilterText} />}
              placement="bottom-end"
            >
              {(popover, toggle) => (
                <div
                  {...popover}
                  className={`${iconButtonContainerClasses} ${totalFilters > 0 ? '!bg-primary-2 bg-opacity-25' : ''}`}
                  {...mouseAndKeyboardCallbackProps(toggle)}
                >
                  <Tooltip text={t('organisation:permissions.tooltips.filter')}>
                    {(tooltip) => <div {...tooltip}>{<FunnelIcon className="h-6 w-5" />}</div>}
                  </Tooltip>
                  {totalFilters > 0 && <div className="px-2 font-medium">{t('table-view:filters.count', { count: totalFilters })}</div>}
                </div>
              )}
            </Popover>

            <Popover content={<UserPermissionsSortingOverview table={table} columnSorting={sorting} />} placement="bottom-end">
              {(popover, toggle) => (
                <div
                  {...popover}
                  className={`${iconButtonContainerClasses} ${totalSorts > 0 ? '!bg-primary-2 bg-opacity-25' : ''}`}
                  {...mouseAndKeyboardCallbackProps(toggle)}
                >
                  <Tooltip text={t('organisation:permissions.tooltips.sorting')}>
                    {(tooltip) => <div {...tooltip}>{<SortIcon className="h-6 w-5" />}</div>}
                  </Tooltip>
                  {totalSorts > 0 && <div className="px-2 font-medium">{t('table-view:sorting.count', { count: totalSorts })}</div>}
                </div>
              )}
            </Popover>

            <div className={`${iconButtonContainerClasses}`} {...mouseAndKeyboardCallbackProps(() => setMemberFieldsModalOpen(true))}>
              <Tooltip text={t('organisation:permissions.tooltips.manage-fields')}>
                {(tooltip) => <div {...tooltip}>{<CogIcon className="h-6 w-5" />}</div>}
              </Tooltip>
            </div>

            {(canScrollLeft || canScrollRight) && (
              <div className="ml-2 flex items-center gap-2">
                <ChevronIcon
                  type={ChevronType.LEFT}
                  className={`${iconButtonClasses} ${!canScrollLeft ? '!cursor-not-allowed opacity-50' : ''}`}
                  onClick={() => canScrollLeft && onScroll(-1 * DEFAULT_COLUMN_SIZE)}
                />
                <ChevronIcon
                  type={ChevronType.RIGHT}
                  className={`${iconButtonClasses} ${!canScrollRight ? '!cursor-not-allowed opacity-50' : ''}`}
                  onClick={() => canScrollRight && onScroll(DEFAULT_COLUMN_SIZE)}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="-mx-6 h-full flex-1 flex-col overflow-hidden p-6">
        {isLoading ? (
          <div className="flex flex-1 items-center justify-center">
            <Loader size={16} centered={false} />
          </div>
        ) : users.length > 0 ? (
          <div
            ref={scrollContainerRef}
            style={{
              width: table.getTotalSize(),
            }}
            className={`max-h-full min-h-0 min-w-full max-w-0 flex-1 overflow-auto`}
            role="table"
            aria-label={t('organisation:permissions.heading')}
            tabIndex={0}
          >
            <div className="sticky top-0 z-10 w-fit">
              {table.getHeaderGroups().map((headerGroup) => (
                <div
                  key={headerGroup.id}
                  style={{
                    width: table.getTotalSize(),
                  }}
                  className="border-gray-5 flex w-fit items-center border border-b-0"
                >
                  {headerGroup.headers.map((header: Header<User, unknown>, i) => (
                    <div
                      key={header.id}
                      style={getCommonPinningStyles(header.column, ['bottom', 'right'])}
                      className={`hover:bg-background-1 group relative ${i !== 0 ? 'border-l' : 'border-0'} border-gray-5 bg-white py-3 pl-2 pr-6 text-left font-normal`}
                    >
                      <div className="flex items-center justify-between gap-1">
                        {header.column.getCanSort() && (
                          <div
                            className={'w-4 cursor-pointer select-none'}
                            {...mouseAndKeyboardCallbackProps(header.column.getToggleSortingHandler())}
                          >
                            <SorterIcon className="text-accent-1 -mt-1 h-6 w-5" direction={header.column.getIsSorted() || 'none'} />
                          </div>
                        )}
                        <div className="flex-1">{header.isPlaceholder ? null : headerCellContent(header)}</div>
                        {header.column.getCanFilter() && (
                          <div className={`truncate ${header.column.getCanFilter() ? 'cursor-pointer' : ''}`}>
                            <Popover
                              ref={(ref) => (filterColumnPopoverRef.current[header.id] = ref!)}
                              content={
                                <UserPermissionsTableFilterContent
                                  column={header.column}
                                  popoverHandles={filterColumnPopoverRef.current}
                                  boolValues={boolFilterText[header.column.id as DefaultMemberFieldKeys]}
                                />
                              }
                              placement="bottom-start"
                            >
                              {(popover, toggle) => (
                                <span {...popover} {...mouseAndKeyboardCallbackProps(toggle)}>
                                  <FunnelIcon className="text-gray-2 hover:border-primary-2 hover:bg-primary-2 float-right h-6 w-6 flex-shrink-0 rounded-md border border-transparent p-1 hover:bg-opacity-25" />
                                </span>
                              )}
                            </Popover>
                          </div>
                        )}
                      </div>
                      {header.column.getCanResize() && (
                        <div
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                        />
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <div className={`w-fit items-center bg-white`}>
              {table.getRowModel().rows.map((row, rowIndex) => {
                return (
                  <div
                    key={`row-${rowIndex}`}
                    style={{
                      width: table.getTotalSize(),
                    }}
                    className="group/row border-gray-5 relative flex items-stretch border-x"
                  >
                    {row.getVisibleCells().map((cell) => (
                      <div
                        key={cell.id}
                        style={getCommonPinningStyles(cell.column, ['bottom'])}
                        className={`group-hover/row:bg-background-1 relative flex flex-col justify-center whitespace-nowrap bg-white pl-2 pr-6`}
                      >
                        <div className="text-dpm-14 truncate py-2 text-gray-500">{dataCellContent(cell)}</div>
                      </div>
                    ))}
                    {(selectedRowIndex === null || selectedRowIndex === rowIndex) && (
                      <div
                        className={`sticky bottom-0 right-0 top-0 z-10 -ml-[23.58px] flex items-center ${selectedRowIndex !== rowIndex ? 'opacity-0' : ''} transition-opacity duration-200 group-hover/row:opacity-100`}
                      >
                        <ContextMenu items={contextItems(row.original)} onMenuToggle={(value) => setSelectedRowIndex(value ? rowIndex : null)} />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div data-cy="user-empty" className="flex h-full justify-center">
            <div className="mt-36 text-center">
              <InfoIcon className="bg-primary-1 text-primary-1 my-2 h-16 w-16 rounded-full bg-opacity-10 p-4" />
              <Heading size={HeadingSize.H3} className="my-4">
                {t('common:permissions.no-users-found')}
              </Heading>
            </div>
          </div>
        )}
      </div>

      <InviteOrSearchModal
        open={inviteUserModalOpen}
        onClose={onInviteClose}
        onInviteNew={inviteUser}
        filterRoles={inviteRoleFilter}
        clientId={currentClient?.id}
      />
      {currentClient && (
        <OrgMemberFieldsModal
          open={memberFieldsModalOpen}
          config={currentClient?.memberFields ?? []}
          onClose={() => {
            setMemberFieldsModalOpen(false);
          }}
          onSave={(config: MemberField[]) => {
            ClientService.updateClient(currentClient.id, { ...currentClient, memberFields: config }).then((res) => {
              toasts.addToast({ title: t('permissions.member-fields-config.saved'), type: ToastType.SUCCESS, expiresInMs: 5000 });
              setClient({ ...currentClient, ...res });
            });
          }}
        />
      )}

      <ModalContext.Provider value={{ open: !!toRemoveUser, onClose: () => setToRemoveUser(null), modalWidth: 'w-2/5' }}>
        <StandardModal
          title={t('permissions.remove-modal.title')}
          onCancelClick={() => setToRemoveUser(null)}
          confirmButtonTitle={t('permissions.remove-modal.confirm')}
          onConfirmClick={() => removeUser(toRemoveUser!)}
        >
          {t('permissions.remove-modal.description', { name: toRemoveUser?.fullName, client: currentClient?.name })}
        </StandardModal>
      </ModalContext.Provider>
    </div>
  );
};

export default UserPermissions;

import { FC, useMemo, useState } from 'react';
import { DropdownSize } from '../../shared/form-control/DropdownSelect';
import { Cell } from '@tanstack/react-table';
import User from '../../../models/User';
import { DefaultMemberFieldKeys } from '../../../models/ClientMemberFields';
import { ClientTagType } from '../../../models/ClientTag';

import TagSelector from '../../tags/TagSelector';
import { useCurrentClient } from '../../../global-state/Clients';
import { useTranslation } from 'react-i18next';
import { ModalContext } from '../../../contexts/ModalContext';
import StandardModal from '../../shared/modal/variants/StandardModal';
import RadioButton from '../../shared/form-control/RadioButton';
import { useQueryClient } from '@tanstack/react-query';
import { useGetActiveClientGroups } from '../../../hooks/useFetchActiveClientGroups';

export const ButtonDropdownClassName =
  'hover:!bg-gray-5 focus:!bg-gray-5 rounded-md !border-0 !bg-transparent [&_svg]:opacity-0 [&_svg]:transition-opacity group-hover/row:[&_svg]:opacity-100';

type Props = {
  cell: Cell<User, unknown>;
  onChange: (newId: string, keepGroupAssignments: boolean) => void;
};

const TAG_TYPE_MAP: Partial<Record<DefaultMemberFieldKeys, ClientTagType>> = {
  groupId: ClientTagType.UserGroup,
  positionId: ClientTagType.UserPosition,
  departmentId: ClientTagType.UserDepartment,
} as const;

const TagDropdownCell: FC<Props> = (props) => {
  const { cell, onChange } = props;
  const { t } = useTranslation('common');
  const [pendingChangeId, setPendingChangeId] = useState<string | null>(null);
  const [keepGroupAssignments, setKeepGroupAssignments] = useState(true);
  const queryClient = useQueryClient();

  const currentClient = useCurrentClient((x) => x.value);

  const tagType = useMemo(() => TAG_TYPE_MAP[cell.column.id as DefaultMemberFieldKeys] as ClientTagType, [cell.column.id]);

  const value = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const field = (cell.column.columnDef?.meta as any)?.field as 'groupId' | 'positionId' | 'departmentId';
    const id = cell.row.original.clientCustomData?.[currentClient!.id]?.[field];

    return id ? [id] : undefined;
  }, [cell.column.columnDef?.meta, cell.row.original.clientCustomData, currentClient]);

  const placeholder = useMemo(() => {
    switch (tagType) {
      case ClientTagType.UserGroup:
        return t('tags.select-group-placeholder');
      case ClientTagType.UserPosition:
        return t('tags.select-position-placeholder');
      case ClientTagType.UserDepartment:
        return t('tags.select-department-placeholder');
    }
  }, [t, tagType]);

  const activeTags = useGetActiveClientGroups(currentClient!);

  return (
    <div>
      <TagSelector
        size={DropdownSize.S}
        type={tagType}
        className={ButtonDropdownClassName}
        onTagsChanged={(tags) => {
          if (tags.length > 0) {
            if (value) {
              setPendingChangeId(tags[0].id);
            } else {
              onChange(tags[0].id, true);
              queryClient.invalidateQueries({
                queryKey: ['clientGroups', currentClient?.id, activeTags],
              });
            }
          }
        }}
        selectedTagIds={value}
        isMulti={false}
        canEditTags
        placeholder={placeholder}
        noOptionsText={t('tags.no-tags-placeholder')}
      />

      <ModalContext.Provider value={{ open: !!pendingChangeId && !!value, modalWidth: 'w-2/5' }}>
        <StandardModal
          title={t('tags.change-users-tag-modal.title')}
          confirmButtonTitle={t('tags.change-users-tag-modal.confirm')}
          onConfirmClick={() => {
            onChange(pendingChangeId!, keepGroupAssignments);
            setPendingChangeId(null);
            queryClient.invalidateQueries({
              queryKey: ['clientGroups', currentClient?.id, activeTags],
            });
          }}
        >
          <div className="mb-4">{t('tags.change-users-tag-modal.note')}</div>

          <RadioButton
            value={keepGroupAssignments}
            onChange={(o) => setKeepGroupAssignments(o)}
            label={t('tags.change-users-tag-modal.choice.keep-group-assignments.title')}
            description={t('tags.change-users-tag-modal.choice.keep-group-assignments.subtext')}
          />

          <RadioButton
            value={!keepGroupAssignments}
            onChange={(o) => setKeepGroupAssignments(!o)}
            label={t('tags.change-users-tag-modal.choice.remove-group-assignments.title')}
            description={t('tags.change-users-tag-modal.choice.remove-group-assignments.subtext')}
          />
        </StandardModal>
      </ModalContext.Provider>
    </div>
  );
};

export default TagDropdownCell;
